import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import React from 'react';

export const sidebarWidth = '600px';
export const titleStyles: React.CSSProperties = {
  fontSize: '18px',
  fontWeight: 'bold',
  color: '#000',
  padding: '16px'
};
export const wholeInfoStyles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-around',
  border: '1px solid #e6e6ea'
};
export const blockStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 0',
  paddingRight: '16px',
  borderRight: '1px solid #e6e6ea'
};
export const textStyles: React.CSSProperties = {
  marginBottom: '10px'
};
export const numberStyles: React.CSSProperties = {
  color: '#000',
  fontSize: '18px'
};
export const chartStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  margin: '16px 0',
  padding: '16px',
  borderBottom: '1px solid #e6e6ea'
};
export const chartTitleStyles: React.CSSProperties = {
  marginBottom: '20px'
};
export const tabsParentStyles: React.CSSProperties = { display: 'flex', justifyContent: 'center', marginTop: '16px' };
export const tabsContentStyles: React.CSSProperties = { padding: '16px' };
