import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { IProcessingChartBar } from './processingChartBar.models';

// Register necessary components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ProcessingChartBar: React.FC<IProcessingChartBar> = ({ label, open }) => {
  // Provided data
  const start = -19.465603863528877;
  const bw = 0.340154949174649;
  const originalFrequencies = [
    3, 2, 6, 37, 96, 258, 524, 1127, 1905, 3260, 4573, 5802, 6511, 6669, 6036, 4885, 3598, 2257, 1300, 664, 313, 127,
    32, 12, 3, 0, 0, 0, 0, 0, 2, 26, 314, 1467, 4756, 9862, 13242, 11447, 6097, 2213, 497, 70, 7, 0, 0, 0, 9, 25, 78,
    189, 527, 1060, 1891, 2952, 4518, 5658, 6599, 24380, 24157, 18652, 14099, 10651, 7774, 5825, 4638, 3726, 3245, 3172,
    3211, 3527, 3772, 4237, 4567, 4885, 5040, 4950, 4565, 4050, 3378, 2811, 2249, 1588, 1114, 765, 510, 294, 204, 134,
    64, 57, 45, 28, 18, 9, 12, 15, 12, 6, 10, 9, 5, 6, 6, 1, 6, 2, 2, 1, 3, 3, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 2, 6, 24,
    86, 272, 714, 1647, 3443, 5477, 7507, 8231, 7940, 6350, 4190, 2425, 1082, 418, 147, 32, 8, 1
  ];

  // State for dynamically changing data
  const [frequencies, setFrequencies] = useState(
    open ? originalFrequencies : Array(originalFrequencies.length).fill(0)
  );

  // Update frequencies based on the `open` prop
  useEffect(() => {
    if (open) {
      setFrequencies(originalFrequencies); // Set original data when open
    } else {
      setFrequencies(Array(originalFrequencies.length).fill(0)); // Set data to zeros when closed
    }
  }, [open]);

  const labels = originalFrequencies.map((_, index) => (start + index * bw).toFixed(2));

  const data = {
    labels,
    datasets: [
      {
        label: label,
        data: frequencies,
        backgroundColor: 'rgba(75, 192, 192, 0.6)'
      }
    ]
  };

  const options: any = {
    scales: {
      x: {
        title: {
          display: true
        }
      },
      y: {
        title: {
          display: true
        },
        beginAtZero: true
      }
    },
    plugins: {
      legend: {
        display: false
      }
    },
    animation: {
      duration: 2000, // Animation duration in milliseconds
      easing: 'easeOutCubic', // Smooth rise effect
      // Animate the y-axis only to make bars grow vertically
      onComplete: (animation: any) => {
        animation.chart.data.datasets.forEach((dataset: any) => {
          dataset.animation = {
            y: {
              duration: 2000
            },
            x: {
              duration: 0 // Prevents any horizontal animation
            }
          };
        });
      },
      animateScale: true,
      animateRotate: false
    }
  };

  return (
    <div>
      <Bar data={data} options={options} />
    </div>
  );
};

export default ProcessingChartBar;
