import React, { useEffect, useState } from 'react';
import { IProcessingTable } from './processingTable.models';
import ProcessingCartBar from '../processingChartBar/processingChartBar.component';
import { Box, Button, CircularProgress } from '@mui/material';
import ProcessingProgressBar from '../processingProgressBar/processingProgressBar.component';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  backIconNameStyles,
  backIconStyles,
  buttonIconStyles,
  circularProgressStyles,
  columnsSpanStyles,
  columnsStyles,
  containerStyles,
  fileNameStyles,
  firstHeaderRowStyles,
  loadingStyles,
  nameAndColumns,
  secondHeaderRowStyles,
  sortingStyles,
  stickyStyles,
  tableScrollStyles,
  tableStyles,
  thirdHeaderRowStyles,
  topBlockButtonsBlockStyles,
  topBlockButtonsStyles,
  topBlockFirstButtonStyles,
  topBlockStyles
} from './processingTable.styles';
import ProcessingTableSummary from '../processingTableSummary/processingTableSummary.component';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import API from '../../../manager/API';
import { removeLastExtension } from '../../../helpers/removeExtension';
import { ReactComponent as SummaryBoxIcon } from './../../../../src/assets/svg/summary.svg';
import { ReactComponent as DownloadBoxIcon } from './../../../../src/assets/svg/download.svg';

const ProcessingTable: React.FC<IProcessingTable> = ({ setActiveStep, datasetOrUpload, processingInputData }) => {
  const [previewData, setPreviewData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [processingTableSummaryOpen, setProcessingTableSummaryOpen] = useState(false);
  const [processingTableSummaryData, setProcessingTableSummaryData] = useState<string>('');
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' } | null>(null);

  const getPreviewUrl = `/data_operations/file_preview?filename=${
    datasetOrUpload === 'dataset' ? processingInputData.dataset : processingInputData.uploadedFileName
  }`;

  // Use optional chaining to safely access nested properties
  const headers = previewData?.preview_data ? Object.keys(previewData.preview_data) : [];
  const numberOfRows = previewData?.preview_data
    ? Math.min(previewData.preview_data[headers[0]]?.values.length || 0, 50)
    : 0;

  const headerClick = (header: string) => {
    setProcessingTableSummaryOpen(true);
    setProcessingTableSummaryData(header);
  };

  const closeProcessingTableSummary = () => {
    setProcessingTableSummaryOpen(false);
  };

  // Fetch preview data asynchronously
  useEffect(() => {
    const fetchPreviewData = async () => {
      setLoading(true);
      try {
        const response = await API.preview.getPreview(getPreviewUrl);
        if (!response) {
          throw new Error('Failed to fetch preview data');
        }
        setPreviewData(response.data);
      } catch (error) {
        console.error('Error fetching preview data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPreviewData();
  }, []);

  // Sorting function
  const handleSort = (header: string) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig && sortConfig.key === header && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key: header, direction });
  };

  function handleBack() {
    setActiveStep(1);
  }

  // Generate the sorted rows data based on the sort configuration
  const sortedRows = () => {
    if (!sortConfig || !previewData) return Array.from({ length: numberOfRows }, (_, index) => index);

    const { key, direction } = sortConfig;
    const rows = Array.from({ length: numberOfRows }, (_, index) => index);

    return rows.sort((a, b) => {
      const aValue = previewData.preview_data[key]?.values[a];
      const bValue = previewData.preview_data[key]?.values[b];

      if (aValue < bValue) return direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return direction === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const rowIds = sortedRows();

  return (
    <>
      {loading ? (
        <div style={loadingStyles}>
          <CircularProgress style={circularProgressStyles} />
        </div>
      ) : previewData ? (
        <>
          <div>
            <div style={containerStyles}>
              <div style={topBlockStyles}>
                <div style={nameAndColumns}>
                  <div style={backIconNameStyles}>
                    <ArrowBackIosNewIcon style={backIconStyles} onClick={handleBack} />
                    <span style={fileNameStyles}>{removeLastExtension(processingInputData.dataset)}</span>
                  </div>
                  <div style={columnsStyles}>
                    <span></span>
                    <span style={columnsSpanStyles}>1,525,154 rows, 142 columns</span>
                  </div>
                </div>
                <div style={topBlockButtonsBlockStyles}>
                  <Button
                    component="label"
                    variant="contained"
                    size="large"
                    sx={topBlockFirstButtonStyles}
                    startIcon={<SummaryBoxIcon width="16px" height="16px" color="#334063" />}
                  >
                    Summary
                  </Button>
                  <Button
                    component="label"
                    variant="contained"
                    size="large"
                    sx={topBlockButtonsStyles}
                    startIcon={<DownloadBoxIcon width="16px" height="16px" color="#334063" />}
                  >
                    download
                  </Button>
                </div>
              </div>
              <div style={tableScrollStyles}>
                <table style={{ ...tableStyles, border: '1px solid #B3B5B9', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr style={firstHeaderRowStyles}>
                      <th key="id" style={{ borderRight: '1px solid #B3B5B9' }}></th>
                      {headers.map((header) => (
                        <th
                          key={header}
                          style={{ borderRight: '1px solid #B3B5B9', padding: '8px' }}
                          onClick={() => handleSort(header)}
                        >
                          <div style={sortingStyles}>
                            <span>{header} </span>
                            {sortConfig?.key === header ? (
                              sortConfig.direction === 'asc' ? (
                                <ArrowUpwardIcon fontSize="small" />
                              ) : (
                                <ArrowDownwardIcon fontSize="small" />
                              )
                            ) : (
                              <ArrowUpwardIcon fontSize="small" />
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                    <tr style={secondHeaderRowStyles}>
                      <th key="id" style={{ borderRight: ' 1px solid #B3B5B9', padding: '8px' }}></th>
                      {headers.map((header) => (
                        <th key={header} style={{ borderRight: '1px solid #B3B5B9', padding: '8px' }}>
                          {previewData.preview_data[header]?.type === 'object' ? 'Category' : 'Number(integer)'}
                        </th>
                      ))}
                    </tr>
                    <tr style={thirdHeaderRowStyles}>
                      <th key="id" style={{ borderRight: '1px solid #B3B5B9', padding: '8px' }}></th>
                      {headers.map((header) => (
                        <th
                          key={header}
                          onClick={() => headerClick(header)}
                          style={{ borderRight: '1px solid #B3B5B9', padding: '8px' }}
                        >
                          {previewData.preview_data[header]?.type === 'object' ? (
                            <ProcessingProgressBar />
                          ) : (
                            <ProcessingCartBar label="" open={true} />
                          )}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody style={{ position: 'relative', zIndex: '1' }}>
                    {rowIds.map((rowIndex) => (
                      <tr key={rowIndex} style={{ borderBottom: '1px solid #B3B5B9' }}>
                        <td key="id" style={{ borderRight: '1px solid #B3B5B9', padding: '8px' }}>
                          {rowIndex + 1}
                        </td>
                        {headers.map((header) => (
                          <td
                            key={header}
                            onClick={() => headerClick(header)}
                            style={{ borderRight: '1px solid #B3B5B9', padding: '8px' }}
                          >
                            {previewData.preview_data[header]?.values[rowIndex] ?? 'N/A'}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <ProcessingTableSummary
            label=""
            open={processingTableSummaryOpen}
            onClose={closeProcessingTableSummary}
            title={processingTableSummaryData}
          />
        </>
      ) : (
        <div>No preview data available</div>
      )}
    </>
  );
};

export default ProcessingTable;
