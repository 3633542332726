import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton
} from '@mui/material';
import { IUploadModal } from './processingUploadModal.models';
import CloseIcon from '@mui/icons-material/Close';
import {
  buttonsStyles,
  cancelButtonStyles,
  closeIconStyles,
  containerStyles,
  continueButtonDisabledStyles,
  continueButtonStyles,
  modalStyles,
  progressBar1Styles,
  progressBar2DisableStyles,
  progressBar2Styles,
  progressStyles,
  titleStyles,
  uploadedFileNameStyles,
  uploadedFileSizeStyles
} from './processingUploadModal.styles';
import { formatBytesFileSizeInGB } from './processingUploadModal.constants';
import { MbConverter } from '../../../helpers/MbConverter';
import { removeLastExtension } from '../../../helpers/removeExtension';

const ProcessingUploadModal: React.FC<IUploadModal> = ({
  onContinue,
  open,
  onClose,
  processingInputData,
  uploadSucceeded
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: modalStyles
      }}
    >
      <div style={containerStyles}>
        <DialogTitle style={titleStyles}>
          Uploading Dataset
          <IconButton onClick={onClose} sx={closeIconStyles}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <div style={uploadedFileNameStyles}>{removeLastExtension(processingInputData.uploadedFileName)}</div>
        <div style={uploadedFileSizeStyles}>({MbConverter(processingInputData.uploadedFileSize)})</div>
        <DialogContent sx={progressStyles}>
          <DialogContentText>
            <div>
              <div style={progressBar1Styles}>
                <div style={uploadSucceeded ? progressBar2Styles : progressBar2DisableStyles} />
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={buttonsStyles}>
          <Button variant="contained" style={cancelButtonStyles} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            style={uploadSucceeded ? continueButtonStyles : continueButtonDisabledStyles}
            onClick={onContinue}
            disabled={!uploadSucceeded}
          >
            Continue
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ProcessingUploadModal;
