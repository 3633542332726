import React, { useState, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  blockStyles,
  chartStyles,
  numberStyles,
  sidebarWidth,
  textStyles,
  titleStyles,
  wholeInfoStyles,
  chartTitleStyles,
  tabsParentStyles,
  tabsContentStyles
} from './processingTableSummary.styles';
import { IProcessingTableSummary } from './processingTableSummary.models';
import ProcessingCartBar from '../processingChartBar/processingChartBar.component';
import ProcessingProgressBar from '../processingProgressBar/processingProgressBar.component';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

const ProcessingTableSummary: React.FC<IProcessingTableSummary> = ({ open, onClose, title, label }) => {
  const [activeTab, setActiveTab] = useState('tab1');
  const tabButtonStyles = (tab: string): React.CSSProperties => ({
    padding: '10px 20px',
    margin: '0 5px',
    border: '1px solid #e6e6ea',
    borderRadius: '5px',
    backgroundColor: activeTab === tab ? '#1976d2' : 'white', // Highlight color for active tab
    color: activeTab === tab ? 'white' : '#1976d2', // Change text color for active tab
    cursor: 'pointer'
  });

  const boxStyles: SxProps<Theme> = {
    position: 'fixed',
    border: '1px solid grey',
    borderRadius: '7px',
    top: 0,
    right: 0,
    width: sidebarWidth,
    height: '100vh',
    backgroundColor: 'white',
    boxShadow: 3,
    zIndex: 1000,
    padding: '0',
    overflowY: 'auto',
    transition: 'transform 1s ease-in-out',
    transform: `translateX(${open ? '0' : `calc(100% + ${sidebarWidth})`})`
  };

  return (
    <Box sx={{ ...boxStyles }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <span style={titleStyles}>{title}</span>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <div style={wholeInfoStyles}>
        <div style={blockStyles}>
          <span style={textStyles}>Rows</span>
          <span style={numberStyles}>10000</span>
        </div>
        <div style={blockStyles}>
          <span style={textStyles}>Empty Rows</span>
          <span style={numberStyles}>0</span>
        </div>
        <div style={blockStyles}>
          <span style={textStyles}>Unique Values</span>
          <span style={numberStyles}>11</span>
        </div>
      </div>
      <div style={chartStyles}>
        <span style={chartTitleStyles}>Distribution</span>
        <ProcessingCartBar label={label} open={open} />
      </div>
      <div style={tabsParentStyles}>
        <button onClick={() => setActiveTab('tab1')} style={tabButtonStyles('tab1')}>
          Tab 1
        </button>
        <button onClick={() => setActiveTab('tab2')} style={tabButtonStyles('tab2')}>
          Tab 2
        </button>
        <button onClick={() => setActiveTab('tab3')} style={tabButtonStyles('tab3')}>
          Tab 3
        </button>
        <button onClick={() => setActiveTab('tab4')} style={tabButtonStyles('tab4')}>
          Tab 4
        </button>
      </div>
      <div style={tabsContentStyles}>
        {activeTab === 'tab1' && (
          <span>
            <ProcessingProgressBar />
            <ProcessingProgressBar />
            <ProcessingProgressBar />
            <ProcessingProgressBar />
          </span>
        )}
        {activeTab === 'tab2' && <span>tab2</span>}
        {activeTab === 'tab3' && <span>tab3</span>}
        {activeTab === 'tab4' && <span>tab4</span>}
      </div>
    </Box>
  );
};

export default ProcessingTableSummary;
