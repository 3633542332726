const ProcessingProgressBar = () => {
  return (
    <div style={{ width: '160px' }}>
      {/* Label and Percentage on Top */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '5px',
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#fff'
        }}
      >
        <div>Marketing</div>
        <div>33%</div>
      </div>

      {/* Progress Bar */}
      <div
        style={{
          width: '100%',
          height: '8px',
          backgroundColor: '#e0e0e0',
          borderRadius: '4px',
          overflow: 'hidden',
          position: 'relative'
        }}
      >
        <div
          style={{
            width: `33%`,
            height: '100%',
            backgroundColor: '#4285f4', // blue color for the progress bar
            transition: 'width 0.3s ease'
          }}
        />
      </div>
    </div>
  );
};

export default ProcessingProgressBar;
